@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.woff2') format('woff2'),
        url('../fonts/Inter-Bold.woff') format('woff'),
        url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter-Medium.woff') format('woff'),
        url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/Inter-SemiBold.woff') format('woff'),
        url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


:root {

    --theme-red: #EA5455;
    --theme-white: #FFFFFF;
    --theme-black: #2E2E2E;
    --theme-green: #109369;
    --theme-lightgreen: #E6F8EE;
    --theme-blue: #31D7EC;
    --theme-lightblue: #E1F9FC;
    --theme-yellow: #FBBC04;
    --theme-lightyellow: #FFF4D4;
    --theme-purple: #8851BE;
    --theme-lightpurple: #F4EDFF;
    --theme-grey: #767D87;
    --theme-lightgrey: #B5B5B5;
    --theme-btnHover: #FBBC04;
    --theme-bordercolor: rgb(225, 225, 225);
    --theme-bg-color: rgb(248, 247, 250);




    --light: "Inter";
    --regular: "Inter";
    --medium: "Inter";
    --semibold: "Inter";
    --bold: "Inter";
    --extrabold: "Inter";
}


body>* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px !important;
    font-family: var(--regular);
    font-weight: normal;
    color: var(--theme-grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--theme-grey);
    letter-spacing: .5px;
    margin-top: 0
}

::selection {
    background-color: var(--theme-green);
    color: var(--theme-white);
}

.bg-red {
    background-color: var(--theme-red);
    color: var(--theme-white);

}

.bg-green {
    background-color: var(--theme-green);
    color: var(--theme-white);

}

.bg-yellow {
    background-color: var(--theme-yellow);
    color: var(--theme-white);

}

.bg-color {
    background-color: var(--theme-bg-color) !important;
    height: 100%;
}

.color-red {
    color: var(--theme-red) !important;
}

.color-green {
    color: var(--theme-green) !important;
}

.color-white {
    color: var(--theme-white) !important;
}

.bold {
    font-family: var(--bold);
    font-weight: bold;
}

.semiBold {
    font-family: var(--semiBold);
    font-weight: 600;
}

@media (prefers-reduced-motion: no-preference) {
    .favicon {
        animation: favicon-spin infinite 15s linear;
    }
}

@keyframes favicon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

p {
    color: var(--lightgrey);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 6px
}

a {
    text-decoration: none !important;
    color: var(--theme-grey) !important;
}

a img {
    -webkit-transition: .3s;
    transition: .3s
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
a:hover {
    color: var(--theme-btnHover) !important;
    text-decoration: none !important;
}

.logo {
    display: flex;
    align-items: center;
}

.favicon {
    width: 100px;
}

.favicon~img {
    width: 150px;
}

.color-theme {
    color: var(--theme-grey);
    letter-spacing: .5px;
    margin-top: 0
}

.login-content {
    width: 70% !important;
}

.color-theme-light {
    color: var(--theme-lightgrey);
    letter-spacing: .5px;
    margin-top: 0
}

.main-heading {
    font-size: 33px;
    font-family: var(--semibold);
    font-weight: 600;

}

.heading-content {
    display: block;
    font-size: 15px;
    font-family: var(--medium);
    font-weight: normal;
    margin: 10px 0;
}

.login-bg {
    background: url(../images/png/bg-layer.png), var(--theme-green);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    background-blend-mode: hard-light;
    height: 450px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10% 0;
}

.login-bg>img {
    width: 60%;
}

.login-icons {
    position: absolute;
    margin: 7px;
}

.form {
    position: relative;
}

.form-group {
    position: relative;
    margin: 20px 0;
}

.form-control {
    font-size: 15px !important;
    padding-left: 40px !important;
    font-family: var(--regular) !important;
    color: var(--theme-lightgrey) !important;
    border: 1px solid var(--theme-lightgrey) !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}


input::placeholder {
    color: var(--theme-lightgrey) !important;
    font-size: 15px !important;
    font-family: var(--regular) !important;
    text-transform: capitalize;
}

.btn-yellow {
    width: 100%;
    padding: 8px;
    border: none;
    outline: 1ps solid var(--theme-yellow);
    border-radius: 8px;
    background-color: var(--theme-yellow);
    color: var(--theme-white);
}

.forget-link {
    position: absolute;
    right: 0;
    bottom: -30px;
    font-size: 14px;
    cursor: pointer;
}

.Footer {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    font-size: 14px;
    padding: 10px;
    text-align: center;
    border-top: 0.01rem solid var(--white);
    background: var(--theme-white);
}

/* ********author css End ********* */


/* ********dashboard css start ********* */
/* ********topbar**********/
.page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 290px;
    background-color: #ffffff;
    z-index: 100;
    transition: .2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.03);
}

.pageTitle {
    font-size: 25px;
    color: var(--theme-grey);
    font-family: var(--semibold);
    font-weight: 600;
}

.dropdown-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.toggle-btn {
    background: transparent !important;
    border: none !important;
}

.toggle-btn>img {
    width: 40px;
    border-radius: 50%;
}

.dropdown-toggle::after {
    color: var(--theme-black);
}

.dropdown-item:hover {
    background-color: var(--theme-green) !important;
}


/************sidebar*************/

.sidebar {
    display: block;
    /* padding-left: 20px; */
    width: 270px;
    min-width: 270px;
    flex-wrap: wrap;
    background: var(--theme-white);
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 110;
    transition: .3s;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 3px rgba(43, 104, 3, 0.2);
}

.sidebar-nav {
    float: left;
    width: 100%;
    height: calc(100% - 105px);
}

.sidebar-nav .main-category-menu {
    float: left;
    width: 100%;
    padding: 20px;
}

.main-category-menu {
    height: 100%;
}

.sidebar-nav-section {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 90%;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: 8px;
    transition: all .6s;
    gap: 15px;
}

.sidebar-nav-section:hover {
    background-color: var(--theme-green);
    padding: 8px;
    border-radius: 8px;
    color: var(--theme-white);
    cursor: pointer;
    color: var(--theme-white) !important;
}

.active {
    background-color: var(--theme-green);
    padding: 8px;
    border-radius: 8px;
    color: var(--theme-white) !important;
}

.sidebar-heading {
    font-size: 16px;
    font-family: var(--medium);
}

.main-content-container {
    width: 100%;
    padding: 100px 20px 40px 280px;
}

.card-common {
    background-color: #fff;
    box-shadow: 0px 7px 17px 2px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-content: flex-start;
    margin: 25px 0 !important;
}

.card-topbar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #E2E2E2;
    padding: 0 20px 0 20px;
    position: relative;
}

.main-page-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px !important;
}

.edit-form-control {
    font-size: 15px !important;
    font-family: var(--regular) !important;
    color: var(--theme-lightgrey) !important;
    border: 1px solid var(--theme-lightgrey) !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 8px !important;
    border-radius: 8px;
}

.from-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: 20px 40px;
}

.btnWrapperPrimary {
    display: flex;
    width: 100%;
    border-top: 1px solid #E2E2E2;
    padding: 20px 20px 20px 20px;
    justify-content: end;
}

.input-field {
    display: flex;
    align-items: baseline;
    gap: 30px;
}

.input-field>input,
.input-field>textarea {
    width: 100%;
}

.input-label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}